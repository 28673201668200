<template>
  <div class="dashboards">
    <div class="dashboard__items">
      <div class="dashboard__item">
        <h4>Статистика по состоянию обсуждения</h4>
        <VueApexCharts type="pie" :options="projectsOptions" :series="projectsSeries"></VueApexCharts>
      </div>
      <div class="dashboard__item">
        <h4>Количество проектов, которые обсуждались на Портале</h4>
        <div class="dashboard__num"><span>{{ numberAnimate(projectsTotalCount) }}</span></div>
      </div>
      <div class="dashboard__item">
        <h4>Количество предложений</h4>
        <VueApexCharts  type="bar" :options="projectsCountOptions" :series="projectsCountSeries"></VueApexCharts>
      </div>
      <div class="dashboard__item">
        <h4>ТОП 3 обсуждаемых проектов</h4>
        <VueApexCharts type="bar" :options="topDiscussionOptions" :series="topDiscussionSeries"></VueApexCharts>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import gsap from 'gsap'

export default {
  components: {
    VueApexCharts,
  },
  data: () => ({
    projectsTotalCount: 0,
    tweenedNumber: 0,
    projectsCountSeries: [{
      name: 'Количество предложений',
      data: []
    }],
    projectsCountOptions: {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        x: {
          formatter: (name) => {
            return name
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: ['Максимальное', 'Медианное','Минимальное'],
        labels: {
          style: {
              cssClass: 'apexcharts-xaxis-label',
          },
          formatter: (name) => {
            return name.slice(0, name === 'Максимальное' ? 4 : 3) + '.'
          },
        },
      }
    },
    projectsSeries: [],
    projectsOptions: {
      legend: {
        show: false,
      },
      labels: []
    },
    topDiscussionSeries: [{
      name: 'Количество предложений',
      data: []
    }],
    topDiscussionOptions: {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        }
      },
      tooltip: {
        x: {
          formatter: (name) => {
            let newName = name.split(' ')
            return _.drop(newName, 1)
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          formatter: (name) => {
            if(typeof name === "string") {
              return _.head(name.split(' '),1)
            } else {
              return name
            }
          },
        },
      }
    }
  }),
  computed: {
    ...mapGetters({
        dashboardsData: 'dashboards/gettersDashboardsData'
    })
  },
  methods: {
    ...mapActions({
        getPortalProjectsCount: 'dashboards/getPortalProjectsCount',
        getProjectDiscussionSummary: 'dashboards/getProjectDiscussionSummary',
        getAuthorCommentsIndicators: 'dashboards/getAuthorCommentsIndicators',
    }),
    numberAnimate: function(newValue) {
      gsap.to(this.$data, { duration: 2, tweenedNumber: newValue });
      return this.tweenedNumber.toFixed(0)
    },
  },
  created() {
    this.getPortalProjectsCount().then(res => {
      this.dashboardsData.projectsCount.map(item => {
        this.projectsSeries.push(item.count);
        this.projectsOptions.labels.push(item.statusName);
        this.projectsTotalCount += item.count;
      });
    });

    this.getProjectDiscussionSummary().then(res => {
      this.projectsCountSeries[0].data = [
        this.dashboardsData.discussionSummary.maxCommentsCount,
        this.dashboardsData.discussionSummary.medianComments,
        this.dashboardsData.discussionSummary.minCommentsCount
      ];

      this.dashboardsData.discussionSummary.topDiscussions.map(item => {
        this.topDiscussionSeries[0].data.push(item.count);
        this.topDiscussionOptions.xaxis.categories.push(item.number + ' ' + item.name);
      });
    });
  },
}
</script>

<style lang='less'>
    @import "./style/mobile";

    @media (min-width: 768px) {
        @import "./style/tablet";
    }

    @media (min-width: 1366px) {
        @import "./style/desktop";
    }
</style>
